import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  inputData : string;
  displayEdStatus:boolean;
  displayExStatus:boolean;
  constructor() { }

  ngOnInit() { 
    this.displayExStatus = true;
  }

  displayData(inputData)
  {
    if(inputData == 'Edu')
    {
      this.displayEdStatus = true;
      this.displayExStatus = false;
    }else{
      this.displayEdStatus = false;
      this.displayExStatus = true;
    }
  }

}
