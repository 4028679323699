import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  toHome()
  {
    document.getElementById("Home").scrollIntoView({behavior:"smooth"});
  }  
  toAbout()
  {
    document.getElementById("About").scrollIntoView({behavior:"smooth"});
  }
  toSkills()
  {
    document.getElementById("Skills").scrollIntoView({behavior:"smooth"});
    
  }
  toGallery()
  {
    document.getElementById("Gallery").scrollIntoView({behavior:"smooth"});
  }
  toProject()
  {
    document.getElementById("Projects").scrollIntoView({behavior:"smooth"});
  }
  toContact()
  {
    document.getElementById("Contact").scrollIntoView({behavior:"smooth"});
  }
}
